.lead-details-container {
  padding: 0px;

  .lead-detail-section {
    background-color: $white-color;
    padding: 20px;
    margin: 0 15px 0 15px;
    border-radius: $border-radius;
    box-shadow: $box-shadow;

    // Sent to agent dropdown adjustment
    .flex-1 {
      margin-right: 10px;
    }

    // Doing a workaround to change the tag color for unread which was white 
    // by default making it unreadable
    .lead-status-tag-unread .ant-tag{
      color: #d4b106;
      background: #feffe6;
      border-color: #fffb8f;
    }
  }

  .customer-link-section {
    background-color: #fff7e6;
    padding: 20px;
    margin: 20px 15px 0 15px;
    border-radius: $border-radius;
    box-shadow: $box-shadow;

    h3, div {
      color: #d46b08;
    }
  }

  .send-link-container {
    display: flex;
    flex-direction: column;
    margin-top: -22px;
  }

  .filter-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    label {
      padding-bottom: 10px;
    }

    input {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }

  .btn-copy {
    margin-right: 10px;
  }

  .lead-bottom-btn {
    margin-left: auto;
  }

  .timeline-icon {
    font-size: 16px;
  }

  .timeline-container {
    max-height: 600px;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-right: 9px; //scrollbar width

    .activity-quote-info{
      font-size: 12px;
      margin: 3px 0;
    }
  }

  .search-section{
    font-size: 10px;
    font-style: italic;
  }

  ::-webkit-scrollbar {
    height: 8px;
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(#333, .2);
    border: 3px solid transparent;
    border-radius: $border-radius;
  }

  .lead-vehicle-img {
    height: 50px;
    width: 125px;
    background-repeat: no-repeat, no-repeat !important;
    background-position: center right !important;
    background-size: 80px auto !important;
    display: inline-block;
  }

  .agent-list{
    width: 200px;
  }

  .lead-website{
    word-break: break-all;
  }

  .associated-lead-text{
    font-size: 16px;
    font-weight: bold;
  }
}

.copy-to-clip-icon{
  font-size: 15px;
  cursor: pointer;

  :hover{
    color: $limo-color;
  }
}

.delete-req-link{
  color: red;
  cursor: pointer;
  &:hover{
    text-decoration: underline;
  }
}