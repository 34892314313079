.matching-vehicle-modal-bootspace .ant-modal-content{
    height: 100%;
    display: flex;
    flex-direction: column;
}

.matching-vehicle-modal-bootspace .ant-modal-body{
    overflow-x: hidden !important;
}

.matching-vehicle-modal-bootspace .ant-list .ant-list-item-action {
    position: absolute;
    right: 5px;
    top: 5px;
}

.hard-fetch-btn{
    position: absolute;
    right: 50px;
    top: 10px;
}