.p-ant-table-container {
    .ant-spin-blur {
        .ant-empty {
            display: none;
        }
    }

    .affiliate-row {
        cursor: pointer;
    }

    .affiliate-row.only-pending td{
        padding-bottom: 0;
    }

    .affiliate-exp-row.pending td{
        border-bottom: 1px solid #888;
    }

    .affiliate-exp-row.pending .ant-table-cell{
        padding-top: 0;
        padding-bottom: 0;
    }

    .exp-row-container{
        display: flex;
        flex-wrap: wrap;
        margin: 0 75px;

        .aff-exp-vehicle{
            width: 25%;
            font-size: 12px;
            margin: 6px 0;
            font-weight: 600;
        }
    }
}

.create-aff-btn{
    margin-right: 10px;
    margin-bottom: 10px;
}