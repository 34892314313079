.request-sent {
    background-color: #EEE;
    text-align: center;
    padding: 5px;
    margin-bottom: 5px;
    border-radius: $border-radius;
    border: 2px solid #DDD;
}

.request-sent.active {
    background-color: #eded6f;
    border: 2px solid #ffe101;
}

.aff-tags{
    margin: 2px;
}

.request-row{
    cursor: pointer;
}

.pending-sent-to-agent-resp {
    font-size: 14px;
    color: #1155CC;
}

.response-action-btn{
    width: 100%;
}