.lead-quote-container {
  padding: 0px;

  .lead-quote-section {
    background-color: #FFF;
    margin: 10px 15px 0 15px;
    padding: 20px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 6px -1px;
  }

  .ant-list-item {
    padding: 0 15px 50px 15px !important;
    cursor: pointer;
  }

  .lead-vehicle-selected {
    background-color: #69b1ff29;
  }

  .lead-filter-input {
    width: 100%;


    .ant-input-number-input {
      margin: 5px !important;
    }
  }

  .lead-filter-label {
    margin-top: 5px;
    margin-bottom: 5px;
    display: block;
  }

  .update-filter-btn {
    margin: 5px 0;
    display: inline-block;
    width: 100%;
  }

  .vehicle-sorting-order {
    width: 100%;
  }

  .ant-list-item-meta-description p {
    margin: 0;
  }

  .lead-quote-name {
    color:#ff0000;
    margin-top: 2px;
    font-weight: bold;
    font-size: 14px;
  }

  .lead-quote-desc {
    color: #333;
    font-size: 14px;

    .cmpny-name {
      font-size: 18px;
    }
  }

  .lead-vehicle {
    border: 1px solid #e8e8e8 !important;
    border-radius: 8px;
  }

  .vehicle-list-container .ant-list {
    border: 0 none;

    .ant-list-item-action {
      position: absolute;
      right: 5px;
      top: 5px;
    }

    .ant-list-item-meta-title {
      min-height: 45px;
      margin-right: 7px;
    }

    .lead-quote-desc {
      min-height: 57px;
    }
  }

  .lead-limo-vehicle{
    background-color: $limo-vehicle-color;
  }

  .badge-vehicle-quote{
    background-color: #FFF;
    border: 1px solid #000;
    border-radius: 50%;
    position: absolute;
    right: 90px;
    bottom: 7px;
    height: 25px;
    width: 25px;
    text-align: center;
    color: #000;

    .ant-scroll-number-only-unit{
      color: #000;
      font-size: 16px;
      font-weight: bold;
    }
  }
}

.vehicle-image-card {
  background-repeat: no-repeat, no-repeat !important;
  background-position: bottom right !important;
  background-size: 80px auto !important;

  .ant-checkbox-inner{
    width: 22px;
    height: 22px;
  }

  .ant-checkbox-inner::after{
    inset-inline-start: 34%;
  }
}

.mile-txt{
  font-size: 19px;
  font-weight: bold;
  color: red;
}

.charter_bus {
  background: url('../../assets/images/cars/charter_bus.png');
}

.shuttle_buses_minibuses {
  background: url('../../assets/images/cars/shuttle_buses_minibuses.png');
}

.sprinter_van_with_passenger_seating {
  background: url('../../assets/images/cars/sprinter_van_with_passenger_seating.png');
}

.sprinter_van_with_limousine_seating {
  background: url('../../assets/images/cars/sprinter_van_with_limousine_seating.png');
}

.party_bus {
  background: url('../../assets/images/cars/party_bus.png');
}

.school_buses {
  background: url('../../assets/images/cars/school_buses.png');
}

.hummer_limo {
  background: url('../../assets/images/cars/hummer_limo.png');
}

.escalade_limo {
  background: url('../../assets/images/cars/escalade_limo.png');
}

.excursion_limo {
  background: url('../../assets/images/cars/excursion_limo.png');
}

.chrysler_limo {
  background: url('../../assets/images/cars/chrysler_limo.png');
}

.range_rover_limo {
  background: url('../../assets/images/cars/range_rover_limo.png');
}

.suburban_limo {
  background: url('../../assets/images/cars/suburban_limo.png');
}

.lincoIn_mkt_limo {
  background: url('../../assets/images/cars/lincoIn_mkt_limo.png');
}

.lincoIn_town_car_limo {
  background: url('../../assets/images/cars/lincoIn_town_car_limo.png');
}

.trolley {
  background: url('../../assets/images/cars/trolley.png');
}

.non_stretch_suv {
  background: url('../../assets/images/cars/non_stretch_suv.png');
}

.non_stretch_sedan {
  background: url('../../assets/images/cars/non_stretch_sedan.png');
}