// Global CSS
*,
::after,
::before {
  box-sizing: border-box;
}

html {
  min-height: 100vh;
  font-size: 14px;
  line-height: 130%;
  font-family: 'Lato', sans-serif;
  // to make sure share modal browser in view-port is transparent
  background-color: transparent;
}

// Specific ID - Which doesn't need it's own file created
#loader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.tbl-red-row {
  background-color: #f2d8dd !important;
}

.tbl-f56c87-row {
  background-color: #f56c87 !important;
}


// .tbl-grey-row {
//   background-color: #fafafa;
// }

.tbl-blue-row,
.tbl-processing-row {
  background-color: #759de355 !important;
}

.tbl-orange-row {
  background-color: #f7c08755 !important;
}

.tbl-yellow-row {
  // background-color: #ffe27f55; // removed for unread
}

.tbl-white-row {
  background-color: #ffffff !important;
}

.tbl-magenta-row {
  background-color: #f097e955 !important;
}

.tbl-volcano-row {
  background-color: #f5ac6c55 !important;
}

.tbl-green-row,
.tbl-success-row {
  background-color: #d2f8dd !important;
}

.tbl-red-row:hover,
.tbl-orange-row:hover,
.tbl-yellow-row:hover,
.tbl-blue-row:hover,
.tbl-green-row:hover,
.tbl-success-row:hover,
.tbl-processing-row:hover {
  // background-color: #fafafa;
}

.ant-table-cell-row-hover{
  background-color: transparent !important;
}

.text-center {
  text-align: center;
  margin: 0 auto;
}

.text-ul {
  text-decoration: underline;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.ml-10 {
  margin-left: 10px;
}

.mr-10 {
  margin-right: 10px;
}

.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.ml-20 {
  margin-left: 20px;
}

.mt-20 {
  margin-top: 20px !important;
}

.mb-10 {
  margin-bottom: 10px;
}

.v-top {
  vertical-align: top;
}

#outer-container {
  padding-left: 230px;
  background-color: #f0f2f5;
  min-height: 100vh;

  @media only screen and (max-width: 1076px) {
    padding-left: 220px;
  }

  @media only screen and (max-width: 768px) {
    padding-left: 220px;
  }
}


.zoom-in {
  zoom: 80%;
  #outer-container {
    min-height: 125vh;
  }
}

.zoom-in-2 {
  zoom: 75%;
  #outer-container {
    min-height: 120vh;
  }
}

#outer-container.push-body {
  padding-left: 75px;
}

.align-start {
  align-items: flex-start;
}

.jc-start {
  justify-content: flex-start;
}

.jc-space-between {
  justify-content: space-between;
}

.align-base {
  align-items: baseline;
}

.txt-cap {
  text-transform: capitalize;
}

.float-right {
  float: right;
}

.display-ib {
  display: inline-block;
}

.ant-table-row>td {
  border-bottom: solid 1px #888 !important
}


.back-icon-container {
  display: inline-block;
  font-size: 18px;

  .back-icon {
    margin-left: 30px;
  }

  .back-text {
    display: inline-block;
  }
}

.back-icon-container:hover {
  color: $limo-color;
  cursor: pointer;
}

.big-website-link {
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}

.generate-link-btn {
  background: #069861;
}
.generate-link-btn:hover{
  background: #53987f !important;
}

.send-reminder-btn {
  background: #f5f522;
  color: #000
}

.send-reminder-btn:hover {
  background:#fdfd68 !important;
  color: #000 !important
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-pointer:hover {
  color: $limo-color;
}

.w-100{
  width: 100%;
}

.camel-case {
  text-transform: capitalize;
}

.ant-spin-fullscreen-custom{
  min-height: 135vh;
  width: 100%;
}

.visibility-hidden {
  visibility: hidden;
}

.w-140 {
  width: 140px;
}

.auto-link{
  background: #8a2be2 !important;
  text-transform: capitalize;
  color: #fff;
  text-align: center;  
}

.customer-link-generated-txt{
  font-size: 17px;
  font-weight: 700;
  margin-top: 20px;
  background: coral;
  text-transform: capitalize;
  color: #fff;
  text-align: center;
  width: 125px;
}