.add-affiliate-container {
    padding: 0px;


    .add-affiliate-section {
        background-color: $white-color;
        padding: 20px;
        margin: 10px 15px 0 15px;
        border-radius: $border-radius;
        box-shadow: $box-shadow;
    }

    .add-affiliate-form-item {
        margin: 10px 0;
    }

    .add-affiliate-form-item-label {
        margin-bottom: 5px;
        font-size: 12px;
        font-weight: 600;
    }
}

.vehicle-select-list-container {
    width: 100%;
    height: 70px;

    .ant-select-selection-item {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
}

.vehicle-color-list-container {
    width: 100%;
}

.vehicle-passenger-list-input {
    width: 100%;
}

.vehicle-select-list-item .ant-select-item-option-content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.vehicle-select-list {
    height: 50px;
    width: 100px;
    background-repeat: no-repeat, no-repeat !important;
    background-position: center left !important;
    background-size: 80px auto !important;
    display: inline-block;
}

.vehicle-color-list-icon {
    height: 20px;
    width: 20px;
    border-radius: 10px;
    display: inline-block;
    margin-right: 5px;
}

.vehicle-list-color-icon{
    height: 10px;
    width: 10px;
    border-radius: 10px;
    display: inline-block;
    margin-right: 5px;
}

.vehicle-color-list-Black {
    background-color: black;
}

.vehicle-color-list-White {
    background-color: rgb(241, 237, 237);
}

.vehicle-color-list-Grey {
    background-color: grey;
}

.vehicle-color-list-Red {
    background-color: red;
}

.vehicle-color-list-Pink {
    background-color: pink;
}

.vehicle-color-list-Yellow {
    background-color: yellow;
}

.vehicle-color-list-Other {
    border: 1px dotted black;
}