.p-ant-table-container {
  padding: 10px;

  .leads-row {
    cursor: pointer;
  }

  .ant-table-wrapper {
    border-radius: $border-radius;
    box-shadow: $box-shadow;
    margin: 10px;
    margin-top: 20px;
  }

  ::-webkit-scrollbar {
    height: 8px;
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(#333, .2);
    border: 3px solid transparent;
    border-radius: $border-radius;
  }
}

.tbl-agent-dropdown{
  .ant-select-selection-placeholder{
    color: #000;
  }
}

.lead-img {
  max-width: 50px;
  max-height: 50px;
  margin: 5px;
}

.lead-card {
  border-radius: $border-radius;
  padding: 15px 5px;
  background-color: $white-color;
  margin: 10px;
  box-shadow: $box-shadow;
  min-height: 120px;
  justify-content: space-evenly;
}

.matching-logic-section{
  font-weight: bold;
  color: black;
  padding: 4px 0;

  .matching-counter{
    padding: 0px;
    margin: 0px;
    font-size: 17px;
  }

  .matching-counter-special {
    color: #fff;
    text-transform: capitalize;
    text-align: center;
    margin: 0 auto;
    background: #cc00ff;
    cursor: pointer;
    box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
    border-radius: 6px;
    line-height: 1.8;
  }

  .matching-counter-special:hover{
    background:#d876f0;
  }
}

.hide-matching-section {
  visibility: hidden;
}

.lead-highlight-row{
  background-color: #ffe27f88;
}

.lead-highlight-row-2{
  background-color: #C8DBFF;
}

.lead-highlight-row-3{
  background-image: linear-gradient(135deg, #d1f5dc 25%, #ffffff 25%, #ffffff 50%, #d1f5dc 50%, #d1f5dc 75%, #ffffff 75%, #ffffff 100%);
  background-size: 50px 50px;
}

.edit-trip-details{
  font-size: 15px;

  :hover{
    color: $limo-color;
  }
}

.lead-actbtn-container:hover{
  color: $limo-color;
}

.trip-reminder-section{
  font-weight: bold;
  font-size: 17px;
  display: block;
}

.agent-list-dropdown{
  width: 140px;
}

.date-picker-container{
  margin: 5px 0;
}

.clear-filter-btn{
  float: right;
  margin-right: 10px;
  margin-bottom: 10px;
}

.additional-link{
  font-weight: bold;
  cursor: pointer;
}

.additional-link:hover{
  text-decoration: underline;
}

.lead-source{
  margin-top: 5px;
  position: absolute;
  width: 500px;
  color: #000000;
  font-weight: bold;
  font-size: 16px;
}