.hover-off-link:hover {
  color: #ffffff !important;
  cursor: pointer;
}

.hover-off-link:hover path {
  fill: #ffffff !important;
}

.company-icon {
  font-family: "company-icon" !important;
}

.my-menu {
  z-index: 10 !important;

  ::-webkit-scrollbar {
    height: 8px;
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(#FFF, .2);
    border: 3px solid transparent;
    border-radius: $border-radius;
  }
}

.my-menu a,
.my-menu a:hover,
.my-menu a:focus {
  color: #ffffff;
}

.menu-container .my-menu.collapse-off {
  overflow-x: hidden;
  max-width: 250px !important;
  left: 0;
  transform: translate3d(0, 0px, 0px) !important;
  width: 250px !important;
  transition: all 0s ease !important;
  -webkit-transition: all 0s ease !important;
  -moz-transition: all 0s ease !important;
  -ms-transition: all 0s ease !important;
}

.menu-container .my-menu.collapse-on {
  left: 0;
  max-width: 75px !important;
  width: 75px !important;
  overflow-x: visible;
  display: block !important;
  transform: none !important;
  transition: none !important;
}

.menu-container .my-menu .bm-menu {
  overflow-y: auto !important;
  width: 230px !important;
  /* transition: width 0.6s;
  -webkit-transition: width 0.6s; */
}

.menu-container .my-menu .bm-menu>nav {
  max-width: 241px;
}

.menu-container .my-menu.collapse-on .bm-menu {
  width: 75px !important;
}

.menu-container .my-menu.collapse-on .bm-menu {
  width: 75px !important;
  overflow: visible !important;
}

.my-menu.collapse-on .company-user-details .company-user-name {
  display: none;
}

.my-menu.collapse-on .company-menu-logo {
  width: 57px;
  margin-right: 0;
  transition: width 0.4s;
  -webkit-transition: width 0.4s;
  margin-top: 35px;
}

.nav-title {
  margin-left: 7px;
  margin-top: 0px;
}

.bm-menu::before {
  background-color: rgba(255, 153, 0, 0.5);
}

.bm-menu {
  font-size: 1.15em;
  background: #212121;
  width: 100% !important;
  padding: 0;
  border-right: 2px solid #383838;
}

.bm-morph-shape {
  width: 0 !important;
}

.menu-blury-container {
  position: fixed;
  right: inherit;
  z-index: 1100;
  height: 100%;
  transition: all 0.3s;
  filter: blur(3px);
  left: 0;
}

.my-menu .bm-item-list {
  color: #9e9e9e;
  display: block;
  font-weight: 400;
  font-size: 12px;
  padding: 8px 7px 8px 7px;
  line-height: 16px;
  outline: 0;
}

nav.bm-item-list {
  overflow-x: hidden;
  overflow-y: scroll;
}

.analytics-img {
  height: 20px;
  width: 20px;
}

.analytics-tab:focus path {
  fill: #0074eb !important;
}

.my-menu .bm-item-list .burger-menu-active {
  color: #0074eb !important;
}

.my-menu .bm-item-list .burger-menu-active:hover,
.my-menu .bm-item-list .burger-menu-active:focus {
  color: #fff;
}

.bm-item-list .bm-item {
  margin: 0 0 12px 0;
  border-radius: 6px;
}

body.push-body .bm-item-list .bm-item {
  margin: 0 0 11px 0;
}

.company-menu-logo-container {
  margin: 40px;
  outline: 0;
  cursor: pointer;
}

.bm-item-list .bm-item.company-menu-logo-container {
  display: block;
  padding-left: 8px;
}

.collapse-on .bm-item-list .bm-item.company-menu-logo-container {
  padding-left: 0px;
  text-align: center;
}

.bm-item-list .bm-item.company-menu-logo-container span {
  display: block;
  color: #eee;
}

.company-user-details {
  text-align: center;
  margin-bottom: 50px;
}

.company-user-details .company-user-avtar {
  margin-bottom: 10px;
}

.bm-item-list .bm-item .company-user-name {
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  color: #fff;
  margin: 0 0 5px 0;
  display: block;
  word-break: break-all;
  white-space: break-spaces;
}

.bm-item-list .bm-item.bm-item-list {
  display: flex !important;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  font-size: 17px;
  padding: 7px;
  text-decoration: none;
}

.bm-item-list.active {
  background-color: $limo-color;
  color: $white-color;
}

.collapse-on .bm-item-list .bm-item.bm-item-list {
  justify-content: center;
}

.bm-item-list .bm-item i {
  font-size: 17px;
  width: 20px;
  height: 20px;
  font-family: "company-icon" !important;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  text-align: center;
  justify-content: center;
}

body.push-body .bm-item-list .bm-item.bm-item-list span.nav-title {
  width: calc(100% - 28px);
  word-break: break-word;
  white-space: pre-wrap;
  position: relative;
}

.my-menu.collapse-on .bm-item-list .bm-item.bm-item-list span.nav-title,
.my-menu.collapse-on span.bm-item-list>div>div>div {
  /* display: none; */
  visibility: hidden;
  opacity: 0;
  position: absolute;
  width: auto;
  left: 79px;
  background: #000;
  padding: 6px 8px;
  top: 56%;
  font-size: 14px;
  text-align: center;
  transform: translateY(-50%);
  border-radius: 5px;
  word-break: inherit;
  white-space: break-spaces;
  line-height: 18px;
  color: #fff;
  margin-left: 0;
  box-shadow: #000 1px 1px 4px;
  -webkit-box-shadow: #000 1px 1px 4px;
  -ms-box-shadow: #000 1px 1px 4px;
  -moz-box-shadow: #000 1px 1px 4px;
  font-weight: 500;
}

.my-menu.collapse-on .bm-item-list .bm-item.bm-item-list:hover span.nav-title {
  visibility: visible;
  opacity: 1;
  transition: all 0.66s ease;
  -webkit-transition: all 0.66s ease;
  -ms-transition: all 0.66s ease;
  -moz-transition: all 0.66s ease;
  z-index: 9;
}

.my-menu.collapse-on .bm-item-list .bm-item.bm-item-list .sub-menu.menu_open span.nav-title {
  visibility: hidden;
  opacity: 0;
}

.my-menu.collapse-on .bm-item-list .bm-item.bm-item-list span.nav-title::before {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 7.5px 7.5px 7.5px 0;
  border-color: transparent #000 transparent transparent;
  position: absolute;
  left: -6px;
  top: 50%;
  transform: translateY(-50%);
}

.bm-item-list .bm-item i.icon-users {
  font-size: 13px;
  height: auto;
}

span.bm-item-list>div>div {
  padding: 8px 50px 8px 37px !important;
  font-size: 12px;
  font-weight: 400 !important;
}

.collapse-on span.bm-item-list>div>div {
  padding: 8px !important;
  min-height: 31px;
  text-align: center;
  pointer-events: none;
}

.collapse-on span.bm-item-list>div>div i {
  left: 16.5px !important;
}

.collapse-on span.bm-item-list>div>div>button {
  position: relative;
  pointer-events: none;
  touch-action: none;
}

.collapse-on span.bm-item-list>div>div>button>div {
  transform: rotateZ(-90deg);
  -webkit-transform: rotateZ(-90deg);
  -ms-transform: rotateZ(-90deg);
  -moz-transform: rotateZ(-90deg);
  position: relative;
  left: 19px;
  top: -1px;
  height: 23px;
}

/* .collapse-off .bm-item-list {
  padding-left: 4px;
} */

.bm-item.bm-item-list:hover>i.company-icon,
.bm-item.bm-item-list:focus>i.company-icon,
.bm-item.bm-item-list:hover>i.company-icon::before,
.bm-item.bm-item-list:focus>i.company-icon::before {
  color: #fff;
}

.bm-item-list .bm-item.bm-item-list .sub-menu {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.bm-item-list .bm-item.bm-item-list .sub-menu:hover {
  cursor: pointer;
}

.bm-item-list .bm-item.bm-item-list.sub-menu-items {
  padding: 0;
}

.bm-item.bm-item-list .sub-menu .sub-menu-name {
  position: relative;
  color: #9e9e9e;
  padding: 8px 7px 8px 7px;
  border-radius: 6px;
  font-size: 14px;
}

.bm-item.bm-item-list.sub-menu-items.burger-menu-active,
.bm-item.bm-item-list.sub-menu-items.burger-menu-active .sub-menu-name {
  color: #0074eb !important;
}

.bm-item.bm-item-list.sub-menu-items.burger-menu-active .menu_open .sub-menu-name i::before {
  color: #0074eb !important;
}

.bm-item.bm-item-list .sub-menu .sub-menu-name:hover,
.bm-item.bm-item-list .sub-menu .sub-menu-name:focus,
.bm-item-list .bm-item.bm-item-list:hover .sub-menu-name,
.bm-item-list .bm-item.bm-item-list:focus .sub-menu-name,
.collapse-on .bm-item-list .bm-item.bm-item-list.sub-menu-items.burger-menu-active:hover,
.collapse-on .bm-item-list .bm-item.bm-item-list.sub-menu-items.burger-menu-active:focus,
.collapse-on .bm-item-list .bm-item.bm-item-list.sub-menu-items.burger-menu-active:hover>.sub-menu-name i.company-icon::before,
.collapse-on .bm-item-list .bm-item.bm-item-list.sub-menu-items.burger-menu-active:focus>.sub-menu-name i.company-icon::before {
  color: #fff;
}

.collapse-on .bm-item-list .bm-item.bm-item-list .sub-menu .sub-menu-name {
  display: flex !important;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center !important;
}

.collapse-on .bm-item-list .bm-item.bm-item-list .sub-menu:hover {
  cursor: pointer;
}

.bm-item-list .bm-item.bm-item-list .sub-menu .sub-menu-name:after {
  content: "\e913";
  font-family: "company-icon";
  position: absolute;
  right: 11px;
  top: 50%;
  font-size: 18px;
  margin-top: -9px;
}

.collapse-on .bm-item-list .bm-item.bm-item-list .sub-menu .sub-menu-name:after {
  right: 3px;
  transform: rotateZ(-90deg);
  margin-top: -7px;
}

.bm-item-list .bm-item.bm-item-list .sub-menu.menu_open .sub-menu-name:after {
  transform: rotateZ(180deg);
}

.collapse-on .bm-item-list .bm-item.bm-item-list .sub-menu.menu_open .sub-menu-name:after {
  transform: rotateZ(90deg);
}

.bm-item-list .bm-item.bm-item-list.burger-menu-active .sub-menu.menu_open .sub-menu-name {
  color: #0074eb;
}

.bm-item-list .bm-item.bm-item-list.burger-menu-active:hover .sub-menu.menu_open .sub-menu-name {
  color: #fff;
}

.bm-item-list .bm-item.bm-item-list .sub-menu .sub-menu-list {
  display: none;
}

.bm-item-list .bm-item.bm-item-list .sub-menu .sub-menu-list>div {
  background: transparent !important;
  border-radius: 6px;
}

.bm-item-list .bm-item.bm-item-list .sub-menu .sub-menu-list .nested-sublist-item {
  background: transparent !important;
  border-radius: 6px;
}

.collapse-on .bm-item-list .bm-item.bm-item-list .sub-menu .sub-menu-list {
  display: block;
}

.bm-item-list .bm-item.bm-item-list .sub-menu.menu_open .sub-menu-list {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%;
  margin-top: 10px;
}

.sub-menu.menu_open .sub-menu-list .nested-sublist-item>div>div {
  padding: 0 !important;
}

.bm-item.bm-item-list.sub-menu-items.burger-menu-active:hover .sub-menu.menu_open .sub-menu-name,
.bm-item.bm-item-list.sub-menu-items.burger-menu-active:hover .sub-menu.menu_open .sub-menu-name i.company-icon::before,
.bm-item.bm-item-list.sub-menu-items.burger-menu-active:hover .sub-menu.menu_open .sub-menu-name span {
  color: #0074eb !important;
}

.bm-item.bm-item-list.sub-menu-items.burger-menu-active:hover .sub-menu-name,
.bm-item.bm-item-list.sub-menu-items.burger-menu-active:focus .sub-menu-name {
  color: #fff;
}

.collapse-on .bm-item-list .bm-item.bm-item-list .sub-menu-list {
  background: #2c2c2c;
  border-radius: 10px;
  color: #9e9e9e;
  min-width: 150px;
  text-align: left;
  position: absolute;
  left: -130px;
  top: -5px;
  padding: 0 !important;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  transform: scale(0.2);
  pointer-events: none;
  box-shadow: #141414 1px 3px 9px;
  -webkit-box-shadow: #141414 1px 3px 9px;
  -ms-box-shadow: #141414 1px 3px 9px;
  -moz-box-shadow: #141414 1px 3px 9px;
}

.sub-menu-items.burger-menu-active {
  background: transparent;
}

.collapse-on .bm-item-list .bm-item.bm-item-list.sub-menu-items {
  position: relative;
  padding: 0;
  min-height: 29px;
  left: -2px;
}

.collapse-on .bm-item-list .bm-item.bm-item-list .sub-menu {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.collapse-on .bm-item-list .bm-item.bm-item-list .menu_open .sub-menu-list {
  opacity: 1;
  visibility: visible;
  pointer-events: visible;
  transform: scale(1.15);
  left: 83px;
  z-index: 99;
  margin-top: 0;
}

.collapse-on .bm-item-list .bm-item.bm-item-list .sub-menu-list a.bm-subitem-list {
  padding: 7.8px 7.8px 7.8px 15px;
  position: relative;
}

.collapse-on .bm-item-list .bm-item.bm-item-list .sub-menu-list a.bm-subitem-list:after {
  content: "\e912";
  display: inline-block;
  position: absolute;
  right: 3px;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  font-family: "company-icon";
  font-size: 35px;
}

.collapse-on .bm-item-list .bm-item.bm-item-list .sub-menu-list a.burger-menu-active {
  color: #fff;
  background: transparent;
  font-weight: 400;
}

.collapse-on .bm-item-list .bm-item.bm-item-list .sub-menu-list a.bm-subitem-list.burger-menu-active:after {
  opacity: 1;
}

span.bm-item-list>div>div i {
  color: #9e9e9e;
  cursor: pointer;
  margin: 7px 0 0 10px !important;
}

span.bm-item-list>div>div button {
  padding: 5px !important;
  width: 52px !important;
  height: 35px !important;
}

.bm-item.sub-menu-items i.icon-home1 {
  font-size: 25.5px;
  top: -2px;
}

span.bm-item-list>div>div button svg {
  width: 18px !important;
}

.company-menu-logo {
  margin: 20px 0;
  transition: all 0.6s ease;
  -webkit-transition: all 0.6s ease;
  -ms-transition: all 0.6s ease;
  -moz-transition: all 0.6s ease;
  width: 100%;
  height: auto;
}

.collapse.in {
  display: block;
}

/* Styling of overlay */

.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.my-menu .bm-subitem-list {
  padding: 7px;
  padding-left: 36px;
  display: block;
  font-size: 14px;
  font-weight: 400;
  border-radius: $border-radius;
  color: #9e9e9e;
}

.bm-subitem-list.menu-disable {
  color: rgba(237, 237, 237, 0.5);
}

.sub-menu-list .bm-subitem-list {
  padding-left: 16px;
}

.burger-menu-active {
  background: rgba(44, 147, 255, 0.1);
  color: #0074eb;
  /* font-weight: 500; */
}

.burger-menu-active i,
.burger-menu-active i::before,
.burger-menu-active .sub-menu-name:after {
  color: #0074eb !important;
}

.burger-menu-active:hover .burger-menu-active:hover i.company-icon {
  color: #fff !important;
}

.bm-item-list .bm-item.bm-item-list.burger-menu-active:hover .sub-menu.menu_open .sub-menu-name:after,
.bm-item-list .bm-item.bm-item-list.burger-menu-active:focus .sub-menu.menu_open .sub-menu-name:after {
  color: #0074eb !important;
}

.burger-menu-active .sub-menu-name i {
  color: inherit !important;
}

.burger-menu-active i {
  color: #0074eb;
}

.bm-burger-button {
  color: #fff;
  position: fixed;
  left: 200px;
  display: inline-block;
  font-size: 20px;
  font-weight: 700;
  cursor: pointer;
  margin-top: 5px;

  @media only screen and (max-width: 1366px) {
    left: 190px;
  }
}

@media only screen and (min-width: 1080px) and (max-width: 1920px) {
  body.push-body .bm-item-list .bm-item.bm-item-list span.nav-title {
    top: 1px;
  }
}

@media only screen and (max-width: 1440px) {
  .company-user-details {
    margin-bottom: 30px !important;
  }

  .bm-item-list .bm-item {
    margin: 0 0 19px 0;
  }
}

@media only screen and (max-width: 1366px) {
  .company-user-details {
    margin-bottom: 25px;
  }

  .bm-item-list .bm-item {
    margin: 0 0 14px 0;
  }
}

@media only screen and (max-width: 1365px) {
  .menu-container .my-menu .bm-menu {
    width: 230px !important;
  }

  .menu-container .my-menu .bm-menu>nav {
    max-width: 221px;
  }

  .menu-container .my-menu,
  .menu-container .my-menu.collapse-off {
    max-width: 230px !important;
  }

  .bm-item-list .bm-item .company-user-name {
    font-size: 15px;
  }

  .bm-item-list .bm-item {
    margin: 0 0 14px 0;
  }
}

@media only screen and (max-width: 1079px) {
  .menu-container .my-menu {
    background: #212121;
  }

  .bm-item-list .bm-item .company-user-name {
    font-size: 14px;
  }

  .menu-container .my-menu .bm-menu,
  .menu-container .my-menu.collapse-off {
    width: 220px !important;
  }

  .menu-container .my-menu .bm-menu>nav {
    max-width: 220px;
  }

  .menu-container .my-menu {
    max-width: 210px !important;
  }

  .bm-item-list .bm-item.bm-item-list .sub-menu .sub-menu-name:after {
    right: 5px;
  }

  .bm-item-list .bm-item {
    margin: 0 0 13px 0;
  }

  .collapse-on .bm-item-list .bm-item.bm-item-list .sub-menu-list a.bm-subitem-list {
    padding: 7.5px 7.5px 7.5px 10px;
    line-height: 17px;
  }
}

@media only screen and (max-width: 1079px) {
  .bm-item-list .bm-item.company-menu-logo-container {
    padding-left: 9px;
  }

  .bm-item.bm-item-list {
    font-size: 15px !important;
  }
}

.bm-burger-button.menu-push {
  left: 26px;
}

.badge-count {
  background: red;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  margin-left: 10px;
  font-size: 12px;
  text-align: center;
  padding-top: 4px;
  color: #fff;
  font-weight: 700;
}